import React, { useState, useRef, useMemo, useEffect } from 'react'
import cn from 'classnames'
import useResizeObserver from 'use-resize-observer'
import FileSaver from 'file-saver'

import Renderer from '~rawlings/client/components/Renderer'
import Icon from '~rawlings/client/components/Icon'
import ViewSwitcher from '~rawlings/client/components/ViewSwitcher'

import '~rawlings/client/components/Renderer.css'

import viewAngles from '../viewAngles'
import { getRenderData } from './RenderData'
import {
  createRenderState,
  updateRenderState,
  disposeRenderState,
  generatePreviews,
} from './RenderState'
import { useCanvas, useCounter } from './hooks'

export const Render3dBase = (props) => {
  const {
    expandedRecipe,
    cameraDef,
    activeView,
    isFocusMode,
    setFocusMode,
    setPreviewGenerator,
  } = props

  const { ref: containerRef, width = 100, height = 100 } = useResizeObserver()
  const [canvas, canvasCallback] = useCanvas()
  const [refreshCounter, incrementRefreshCounter] = useCounter()

  const renderStateRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isCrashed, setIsCrashed] = useState(false)
  const isScrollMenuLayout = props.layoutMode !== 'mobile' && !props.isFocusMode

  const renderData = useMemo(
    () => getRenderData(expandedRecipe, cameraDef, width, height),
    [expandedRecipe, cameraDef, width, height],
  )

  let renderState = renderStateRef.current
  if (!renderState && canvas && renderData) {
    const seed = {
      canvas,
      onLoadUpdate: () => {
        incrementRefreshCounter()
        const renderState = renderStateRef.current
        if (!renderState) {
          return
        }
        setIsLoading(renderState.isLoading)
      },
      onCrash: () => {
        setIsCrashed(true)
      },
    }
    // setTimeout(seed.onCrash, 15000)
    renderState = createRenderState(seed, renderData)
    renderStateRef.current = renderState
  }

  useMemo(() => {
    if (!renderState) {
      return
    }
    if (!renderData) {
      return
    }
    updateRenderState(renderState, renderData)
    setIsLoading(renderState.isLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Cache-busting...
  }, [renderState, renderData, refreshCounter])

  const hasRenderState = !!renderState
  useEffect(() => {
    if (!hasRenderState) {
      return () => {}
    }
    setPreviewGenerator(async (viewNames) => {
      await new Promise((resolve) => {
        const checkReady = () => {
          if (renderStateRef.current && !renderStateRef.current.isLoading) {
            resolve()
          }
          setTimeout(checkReady, 50)
        }
        checkReady()
      })

      if (!renderStateRef.current) {
        throw new Error('renderStateRef.current not defined')
      }
      return generatePreviews(renderStateRef.current, viewNames)
    })

    return () => {
      console.log()
      const renderState = renderStateRef.current
      if (renderState) {
        disposeRenderState(renderState)
        renderStateRef.current = null
      }
      setPreviewGenerator(undefined)
    }
  }, [hasRenderState, setPreviewGenerator])

  const filename = useMemo(
    () =>
      `${
        (expandedRecipe.helmet &&
          expandedRecipe.helmet.model &&
          expandedRecipe.helmet.model.id) ||
        'image'
      }.png`,
    [expandedRecipe.helmet],
  )
  useEffect(() => {
    if (!canvas) {
      return undefined
    }

    window.saveImage = async () => {
      if (!renderStateRef.current) {
        return
      }

      const blobs = await generatePreviews(renderStateRef.current, [
        'thumbnail',
      ])

      FileSaver.saveAs(blobs.thumbnail, filename)
    }
    return () => {
      delete window.saveImage
    }
  }, [canvas, filename])

  return (
    <div>
      {!isFocusMode && (
        <a
          className={cn(
            'preview-focus',
            isScrollMenuLayout && 'mod-is-scroll-menu-layout',
          )}
          onClick={(ev) => {
            ev.preventDefault()
            setFocusMode(true)
          }}
        >
          <Icon name="magnifier" />
        </a>
      )}
      <div
        className={cn('preview', {
          'is-focus': isFocusMode,
          'is-covering-background': true,
        })}
      >
        <ViewSwitcher
          viewAngles={viewAngles}
          activeView={activeView}
          setFocusMode={setFocusMode}
          isFocusMode={isFocusMode}
        >
          <Renderer isLoading={isLoading} isFocusMode={isFocusMode}>
            <div
              ref={containerRef}
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <canvas
                ref={canvasCallback}
                style={{
                  width: '100%',
                  height: '100%',
                  touchAction: 'none',
                }}
              />
            </div>
          </Renderer>
        </ViewSwitcher>
        {isCrashed && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'colum',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 3,
              // cursor: 'pointer',
            }}
          >
            Rendering crashed
            <br />
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                window.location.reload()
              }}
            >
              Reload current state
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

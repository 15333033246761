import * as _ from '@technically/lodash'
import { withProps } from 'recompose'

import {
  createControlTree,
  Select,
  Text,
  unspecifiedValue,
} from '~p/client/control-tree'

import {
  PROP_DEF_DICT,
  COLOR_DICT,
  COLOR_DEFS,
  FINISHES,
  COLORS,
  FLAP_SIDES,
  FACEGUARD_MODELS,
  STYLES,
  SIZES,
  MODELS,
  SPORTS,
  VENDOR_DICT,
} from '../common/sheets'

const boilerplate = (propDef) => {
  const tileImageTemplate =
    propDef.tileImageProps && _.template(propDef.tileImageProps)

  return {
    label: propDef.name,
    description: propDef.description,
    defaultValue:
      'defaultValueId' in propDef && propDef.defaultValueId ?
        propDef.defaultValueId
      : null,
    isRequired: !propDef.isOptional,
    extraCost: propDef.extraCost,
    tileType: propDef.tileType,
    viewName: propDef.viewNames[0],
    tileImageTemplate: tileImageTemplate ? () => tileImageTemplate : null,
  }
}
const getColorsForFinish = (finish) => {
  const colorDefs = _.filter(
    COLOR_DEFS,
    (colorDef) =>
      !colorDef.props.isDisabled && colorDef.props.finishId === finish.id,
  )
  return _.map(colorDefs, (colorDef) => COLOR_DICT[colorDef.props.colorId])
}

const getAreaNodes = (partId, number) => {
  const finishPropDefId = `${partId}_area${number}_finish`
  const colorPropDefId = `${partId}_area${number}_color`

  return {
    finish: Select({
      ...boilerplate(PROP_DEF_DICT[finishPropDefId]),
      dependencies: [`calc.${partId}.colorCount`],
      isAvailable: (colorCount) => colorCount >= number,
      options: FINISHES,
    }),

    color: Select({
      ...boilerplate(PROP_DEF_DICT[colorPropDefId]),
      dependencies: [`${partId}.area${number}.finish`, 'helmet.style'],
      isAvailable: (finish) => !!finish,
      options: COLORS,
      visibleOptions: (finish) => getColorsForFinish(finish),
      defaultValue: (_finish, style) => style.props[`color${number}`],
    }),
  }
}

const controlTree = createControlTree({
  env: {
    currency: Text({
      isPrivate: true,
      value: () => () => ({
        props: {
          prefix: 'USD',
        },
      }),
    }),

    vendor: Text({
      isPrivate: true,
      value: () => () => VENDOR_DICT[window.serverConfig?.hostname ?? ''],
    }),
  },

  calc: {
    sku: Text({
      isPrivate: true,
      dependencies: ['helmet.model', 'helmet.size'],
      isAvailable: (model, size) => !!(model && size),
      value: (model, size) => () => `${model.id}-${size.id}`,
    }),

    skuLabel: Text({
      isPrivate: true,
      dependencies: ['helmet.model', 'helmet.size'],
      isAvailable: (model, size) => !!(model && size),
      value: (model, size) => () => `${model.id}-${size.name}`,
    }),

    price: Text({
      isPrivate: true,
      dependencies: ['helmet.model', 'faceguard.model'],
      value: (model, faceguardModel) => () =>
        _.sumBy([model, faceguardModel], (node) =>
          node ? node.props.price : 0,
        ),
    }),

    priceWithCurrency: Text({
      isPrivate: true,
      dependencies: ['env.currency', 'calc.price'],
      value: (currency, price) => () =>
        `${currency.props.prefix} ${price.toFixed(2)}`,
    }),

    helmet: {
      colorCount: Text({
        isPrivate: true,
        dependencies: ['helmet.style'],
        isAvailable: (style) => !!style,
        value: (style) => () => 1 + style.props.accentCount,
      }),
    },
    faceguard: {
      models: Text({
        dependencies: ['filter.sport', 'helmet.size', 'helmet.model'],
        isAvailable: (sport, size, model) => !!sport && !!size && !!model,
        value: (sport, size, model) => () =>
          _.filter(
            FACEGUARD_MODELS,
            ({ subset }) =>
              subset.sportId[sport.id] &&
              (size.id === 'senior' || size.id === 'junior' ?
                subset.sizeId[size.id]
              : true) &&
              subset.modelId[model.id],
          ),
      }),
      colorCount: Text({
        isPrivate: true,
        dependencies: ['faceguard.model'],
        isAvailable: (model) => !!model,
        value: (model) => () => model.props.areaCount,
      }),
    },
  },

  filtered: {
    modelsBySport: Text({
      isPrivate: true,
      dependencies: ['filter.sport'],
      value: (sport) => () =>
        sport == null ? MODELS : (
          _.filter(MODELS, (model) => model.subset.sportId[sport.id])
        ),
    }),
  },

  filter: {
    sport: Select({
      ...boilerplate(PROP_DEF_DICT.filter_sport),
      options: SPORTS,
    }),
  },

  helmet: {
    model: Select({
      ...boilerplate(PROP_DEF_DICT.helmet_model),
      dependencies: ['filter.sport'],
      isAvailable: (sport) => !!sport,
      options: MODELS,
      visibleOptions: (sport) =>
        _.filter(MODELS, (model) => model.subset.sportId[sport.id]),
    }),

    size: Select({
      ...boilerplate(PROP_DEF_DICT.helmet_size),
      dependencies: ['helmet.model'],
      isAvailable: (model) => !!model,
      options: SIZES,
      visibleOptions: (model) =>
        _.filter(SIZES, (size) => size.subset.modelId[model.id]),
    }),

    style: Select({
      ...boilerplate(PROP_DEF_DICT.helmet_style),
      dependencies: ['helmet.model'],
      isAvailable: (model) => !!model,
      options: STYLES,
      visibleOptions: (model) =>
        _.filter(STYLES, (style) => style.subset.modelId[model.id]),
    }),

    ..._.reduce(
      [1, 2, 3], // 1 is for base area, 2 & 3 are for accent areas.
      (a, number) => _.set(a, `area${number}`, getAreaNodes('helmet', number)),
      {},
    ),
  },

  faceguard: {
    model: Select({
      ...boilerplate(PROP_DEF_DICT.faceguard_model),
      dependencies: ['filter.sport', 'calc.faceguard.models', 'env.vendor'],
      isAvailable: (sport, models) => !!sport && !!models,
      autoUnavailable: true,
      options: FACEGUARD_MODELS,
      visibleOptions: (sport, models, vendor) =>
        models.filter(({ id }) => !vendor.disabledFaceGuards?.includes(id)),
      defaultValue: (sport, models) => {
        if (sport.id === 'softball' && models.length > 0) {
          return models[0].id
        }
        return unspecifiedValue
      },
    }),

    ..._.reduce(
      [1, 2], // 1 is for base area, 2 is for accent area.
      (a, number) =>
        _.set(a, `area${number}`, getAreaNodes('faceguard', number)),
      {},
    ),

    flapSide: Select({
      ...boilerplate(PROP_DEF_DICT.faceguard_flapSide),
      dependencies: ['faceguard.model'],
      isAvailable: (faceguardModel) =>
        !!faceguardModel && faceguardModel.props.hasSide,
      options: FLAP_SIDES,
    }),
  },

  summary: {
    purchase: Text({
      isPrivate: true,
      value: () => () => true,
      label: 'End',
    }),
  },

  legacy: {
    sku: Text({
      isPrivate: true,
      dependencies: ['helmet.model'],
      isAvailable: (model) => !!model,
      value: (model) => () => model.props.sku,
    }),
  },
})

export default controlTree

export const withCT = withProps({ controlTree })

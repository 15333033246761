// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ModelRow } from '../typings';

const data = [
  {
    "id": "ProMax",
    "name": "PRO MAX",
    "description": "Get your Pro Max helmet with Multi-density Protection and Game Ready Fit, coupled with razor sharp design.",
    "props": {
      "icon": "ProMax",
      "sku": "PROMAXBH-CONFIG",
      "price": 89.99,
      "assetIds": {
        "base": "ProMax"
      }
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": false
      }
    }
  },
  {
    "id": "GHOST",
    "name": "GHOST",
    "description": "A helmet designed from the ground-up for softball players, the Ghost has 3 sizes optimized to protect you, so you look sleak and feel confident.",
    "props": {
      "icon": "GHOST",
      "sku": "GHOSTFPBH-CONFIG",
      "price": 69.99,
      "assetIds": {
        "base": "GHOST"
      }
    },
    "subset": {
      "sportId": {
        "baseball": false,
        "softball": true
      }
    }
  },
  {
    "id": "Z5",
    "name": "Z5",
    "description": "The perfect balance of comfort and look. Find out why the Z5 launched many imitators.",
    "props": {
      "icon": "Z5",
      "sku": "Z52BH-CONFIG",
      "price": 69.99,
      "assetIds": {
        "base": "Z5"
      }
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": true
      }
    }
  },
  {
    "id": "ALPHA",
    "name": "ALPHA",
    "description": "The Alpha has best in class comfort with extra plush foams and perfect sizing that fits a huge range of heads sizes and head shapes.",
    "props": {
      "icon": "ALPHA",
      "sku": "ALPHABH-CONFIG",
      "price": 49.99,
      "assetIds": {
        "base": "ALPHA"
      }
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": true
      }
    }
  },
  {
    "id": "ProX",
    "name": "PRO-X SKULL CAP",
    "description": "Round out your team's look by getting a Pro X Skull Cap that matches your custom batting helmets.",
    "props": {
      "icon": "ProX",
      "sku": "PROXSKULLCAP-CONFIG",
      "price": 59.99,
      "assetIds": {
        "base": "ProX"
      }
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": true
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ModelRow[]>> = data;

export type Model = typeof typed[number];

export const MODEL_INDEX_KEY = "id";
export type ModelIndexKey = "id";
export type ModelId = Model["id"];

let i = 0;
export const MODEL_DICT = {
  "ProMax": typed[i++],
  "GHOST": typed[i++],
  "Z5": typed[i++],
  "ALPHA": typed[i++],
  "ProX": typed[i++]
} as const;

export { typed as MODELS };

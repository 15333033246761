// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { StyleRow } from '../typings';

const data = [
  {
    "id": "A1",
    "name": "Bill Accent",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoBg": {
        "sideDefault": "W"
      },
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": true,
        "Z5": false,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "A2",
    "name": "Throw Back",
    "props": {
      "accentCount": 2,
      "color1": "W",
      "color2": "CB",
      "color3": "S",
      "techLogoBg": {
        "sideDefault": "W"
      },
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": true,
        "Z5": false,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "A3",
    "name": "Wings",
    "props": {
      "accentCount": 1,
      "color1": "S",
      "color2": "W",
      "techLogoBg": {
        "sideDefault": "W"
      },
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": true,
        "Z5": false,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "A4",
    "name": "Back Space",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoBg": {
        "sideDefault": "W"
      },
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": true,
        "Z5": false,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "A5",
    "name": "Vents",
    "props": {
      "accentCount": 1,
      "color1": "S",
      "color2": "W",
      "techLogoBg": {
        "sideDefault": "W"
      },
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": true,
        "Z5": false,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "A6",
    "name": "Angles",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoBg": {
        "sideDefault": "W"
      },
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": true,
        "Z5": false,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "A8",
    "name": "Flag",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoBg": {
        "sideDefault": "W"
      },
      "techLogoArea": "area2"
    },
    "subset": {
      "modelId": {
        "ProMax": true,
        "Z5": false,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "A10",
    "name": "Electric",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoBg": {
        "sideDefault": "W"
      },
      "techLogoArea": "area2"
    },
    "subset": {
      "modelId": {
        "ProMax": true,
        "Z5": false,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "A11",
    "name": "Sunrise",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoBg": {
        "sideDefault": "W"
      },
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": true,
        "Z5": false,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "C1",
    "name": "Bill Accent",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": true,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "C2",
    "name": "Throw Back",
    "props": {
      "accentCount": 2,
      "color1": "W",
      "color2": "CB",
      "color3": "S",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": true,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "C5",
    "name": "Vents",
    "props": {
      "accentCount": 1,
      "color1": "S",
      "color2": "W",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": true,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "C6-2",
    "name": "Wings",
    "props": {
      "accentCount": 1,
      "color1": "S",
      "color2": "W",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": true,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "C10",
    "name": "Fireball",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": true,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "C11",
    "name": "Transitions",
    "props": {
      "accentCount": 1,
      "color1": "S",
      "color2": "W",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": true,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "C12",
    "name": "Sunrise",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": true,
        "ALPHA": false,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "D1",
    "name": "Bill Accent",
    "props": {
      "accentCount": 1,
      "color1": "CB",
      "color2": "S",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": true,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "D2",
    "name": "Throw Back",
    "props": {
      "accentCount": 2,
      "color1": "CB",
      "color2": "W",
      "color3": "S",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": true,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "D3",
    "name": "Front Panel",
    "props": {
      "accentCount": 1,
      "color1": "CB",
      "color2": "W",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": true,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "B1",
    "name": "Bill Accent",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "knockoutLogoArea": "area1",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": true,
        "ProX": false
      }
    }
  },
  {
    "id": "B2",
    "name": "Throw Back",
    "props": {
      "accentCount": 2,
      "color1": "W",
      "color2": "CB",
      "color3": "S",
      "knockoutLogoArea": "area1",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": true,
        "ProX": false
      }
    }
  },
  {
    "id": "B3",
    "name": "Zones",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoArea": "area2"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": true,
        "ProX": false
      }
    }
  },
  {
    "id": "B4",
    "name": "Back Space",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "knockoutLogoArea": "area1",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": true,
        "ProX": false
      }
    }
  },
  {
    "id": "B5",
    "name": "Vents",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": true,
        "ProX": false
      }
    }
  },
  {
    "id": "B5-2",
    "name": "Curve",
    "props": {
      "accentCount": 1,
      "color1": "S",
      "color2": "W",
      "techLogoArea": "area2"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": true,
        "ProX": false
      }
    }
  },
  {
    "id": "B7",
    "name": "Flames",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoArea": "area2"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": true,
        "ProX": false
      }
    }
  },
  {
    "id": "B10",
    "name": "Wings",
    "props": {
      "accentCount": 1,
      "color1": "S",
      "color2": "W",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": true,
        "ProX": false
      }
    }
  },
  {
    "id": "B11",
    "name": "Sunrise",
    "props": {
      "accentCount": 1,
      "color1": "W",
      "color2": "S",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": true,
        "ProX": false
      }
    }
  },
  {
    "id": "E1",
    "name": "Bill Accent",
    "props": {
      "accentCount": 1,
      "color1": "B",
      "color2": "S",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": false,
        "ProX": true
      }
    }
  },
  {
    "id": "E2",
    "name": "Solid",
    "props": {
      "accentCount": 0,
      "color1": "B",
      "techLogoArea": "area1"
    },
    "subset": {
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": false,
        "ProX": true
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<StyleRow[]>> = data;

export type Style = typeof typed[number];

export const STYLE_INDEX_KEY = "id";
export type StyleIndexKey = "id";
export type StyleId = Style["id"];

let i = 0;
export const STYLE_DICT = {
  "A1": typed[i++],
  "A2": typed[i++],
  "A3": typed[i++],
  "A4": typed[i++],
  "A5": typed[i++],
  "A6": typed[i++],
  "A8": typed[i++],
  "A10": typed[i++],
  "A11": typed[i++],
  "C1": typed[i++],
  "C2": typed[i++],
  "C5": typed[i++],
  "C6-2": typed[i++],
  "C10": typed[i++],
  "C11": typed[i++],
  "C12": typed[i++],
  "D1": typed[i++],
  "D2": typed[i++],
  "D3": typed[i++],
  "B1": typed[i++],
  "B2": typed[i++],
  "B3": typed[i++],
  "B4": typed[i++],
  "B5": typed[i++],
  "B5-2": typed[i++],
  "B7": typed[i++],
  "B10": typed[i++],
  "B11": typed[i++],
  "E1": typed[i++],
  "E2": typed[i++]
} as const;

export { typed as STYLES };

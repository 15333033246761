import { MODEL_DICT, SIZE_DICT } from './sheets'

const PRODUCT_TITLE = `Custom Easton Batting Helmets`

const getIndexTitle = () => 'Easton Batting Helmet Builder'

const getIndexDescription = () =>
  'Build your own batting helmet in a photorealistic customizer.'

const getSkuDescription = () =>
  'Build your own batting helmet based on this template.'

const getRecipeDescription = () =>
  'Order this batting helmet or build your own based on it.'

const getVia = (config) => {
  if (config.hideVia || config.vendorName == null) {
    return ''
  }
  return ` via ${config.vendorName}`
}

const getSkuValues = (sku) => {
  const [modelId, sizeId] = sku.split('-')
  const model = MODEL_DICT[modelId]
  const size = SIZE_DICT[sizeId]
  return {
    model,
    size,
  }
}

const getSkuTitle = (config, params) => {
  const sku = params[0]
  const { model } = getSkuValues(sku)

  return `${model.name} - ${PRODUCT_TITLE} Template${getVia(config)}`
}

const getRecipeTitle = (config, recipe, id, hasChanges) =>
  `Design #${id}${hasChanges ? ' with changes' : ''} - ${PRODUCT_TITLE}${getVia(
    config,
  )}`

const getStaticTags = () => ''

const garmentSizes = []

export {
  getIndexTitle,
  getIndexDescription,
  getSkuValues,
  getSkuTitle,
  getSkuDescription,
  getRecipeTitle,
  getRecipeDescription,
  getStaticTags,
  garmentSizes,
}
